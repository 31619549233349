<template>
  <div>
    <CContainer>
      <CRow>
        <CCol md="12">
          <CCardGroup>
            <CCard>
              <CCardHeader>
                <h3 v-if="$route.params.id">Редактирование теста</h3>
                <h3 v-else>Создание теста</h3>
              </CCardHeader>
              <CCardBody>
                <CForm @submit.prevent="requestQuizUpdate">
                  <CInput v-model="quizData.text" label="Название теста" placeholder="" @input="requestQuizUpdate">
                  </CInput>
                  <div v-if="quiz">
                    <CRow>
                      <CCol>
                        <h3>Вопросы</h3>
                      </CCol>
                    </CRow>
                    <CRow v-if="questions.length">
                      <CCol>
                        <div v-for="(question, index) in questions" :key="index">
                          <CRow>
                            <CCol md="8">
                              <CTextarea rows="4" v-model="questions[index].text" :label="`Текст вопроса ${index + 1}`"
                                placeholder="" @input="requestQuestionUpdate(index)">
                              </CTextarea>
                              <CTextarea rows="4" v-model="questions[index].description" :label="`Описание ${index + 1}`"
                                placeholder="" @input="requestQuestionUpdate(index)">
                              </CTextarea>
                              <CTextarea rows="4" v-model="questions[index].explanation_text" :label="`Краткое объснение ${index + 1}`"
                                placeholder="" @input="requestQuestionUpdate(index)">
                              </CTextarea>
                              <CTextarea rows="4" v-model="questions[index].explanation_description" :label="`Полное объяснение ${index + 1}`"
                                placeholder="" @input="requestQuestionUpdate(index)">
                              </CTextarea>
                              <CInput v-model="questions[index].order" label="Порядковый номер" placeholder=""
                                @input="requestQuestionUpdate(index)" type="number">
                              </CInput>
                              <br>
                              <CInputFile :data-index="index"
                                label="Картинка для вопроса"
                                placeholder=""
                                @change="uploadQuizQuestionImage"
                              />
                              <div v-if="questions[index].image">
                                <img style="max-width: 400px" :src="questions[index].image" />
                              </div>
                              <br>
                              <CInputFile :data-index="index"
                                label="Аудио для вопроса"
                                placeholder=""
                                @change="uploadQuizQuestionAudio"
                              />
                              <div v-if="questions[index].audio">
                                <audio controls>
                                  <source :src="questions[index].audio" type="audio/mpeg">
                                </audio>
                              </div>
                              <br>
                            </CCol>
                            <CCol>
                              <CButton style="margin-top: 28px" color="danger" type="button"
                                @click="deleteQuestion(index)">
                                <span>Удалить</span>
                              </CButton>
                            </CCol>
                          </CRow>
                          <CRow style="margin-bottom: 60px">
                            <CCol md="1"> </CCol>
                            <CCol md="11">
                              <CRow>
                                <CCol>
                                  <h5>Ответы</h5>
                                </CCol>
                              </CRow>
                              <CRow v-if="answers[question.id]">
                                <CCol>
                                  <div v-for="(answer, index) in answers[
                                    question.id
                                  ]" :key="index">
                                    <CRow>
                                      <CCol md="5">
                                        <CTextarea rows="4" v-model="answer.text" :label="`Текст ответа ${index + 1}`"
                                          placeholder="" @input="
                                            requestAnswerUpdate(
                                              question.id,
                                              index
                                            )
                                          ">
                                        </CTextarea>
                                      </CCol>

                                      <CCol md="5">
                                        <CTextarea rows="4" v-model="answer.explanation_text" :label="`Краткое объяснение ответа ${index + 1}`"
                                          placeholder="" @input="
                                            requestAnswerUpdate(
                                              question.id,
                                              index
                                            )
                                          ">
                                        </CTextarea>
                                      </CCol>

                                      <CCol md="5">
                                        <CTextarea rows="4" v-model="answer.explanation_description" :label="`Длинное объяснение ответа ${index + 1}`"
                                          placeholder="" @input="
                                            requestAnswerUpdate(
                                              question.id,
                                              index
                                            )
                                          ">
                                        </CTextarea>
                                      </CCol>

                                      <CInput v-model="answer.match" :label="`Метка ответа ${index + 1}`" placeholder="" @input="
                                            requestAnswerUpdate(
                                              question.id,
                                              index
                                            )
                                          "></CInput>

                                      <!-- CCol md="2">
                                        <CSelect
                                          :value.sync="answer.person_type"
                                          label="Тип личности"
                                          :options="personTypes"
                                          placeholder=""
                                          @change="
                                            requestAnswerUpdate(
                                              question.id,
                                              index
                                            )
                                          "
                                        />
                                      </CCol -->
                                      <CCol>
                                        <input type="checkbox" v-model="answer.correct" @change="
                                            requestAnswerUpdate(
                                              question.id,
                                              index
                                            )
                                          " /> <label>Правильный
                                          ответ?</label>
                                      </CCol>
                                      <CCol>
                                        <CButton style="margin-top: 28px" color="danger" type="button" @click="
                                          deleteAnswer(question.id, index)
                                        ">
                                          <span>Удалить</span>
                                        </CButton>
                                      </CCol>
                                    </CRow>
                                  </div>
                                </CCol>
                              </CRow>
                              <div v-if="newAnswersData[question.id]">
                                <CRow>
                                  <CCol>
                                    <h5>Добавить ответ</h5>
                                  </CCol>
                                </CRow>
                                <CRow>
                                  <CCol md="5">
                                    <CTextarea rows="4" v-model="newAnswersData[question.id].text" label="Текст ответа"
                                      placeholder="">
                                    </CTextarea>
                                  </CCol>

                                  <CCol md="5">
                                    <CTextarea rows="4" v-model="newAnswersData[question.id].explanation_text" label="Краткое объяснение ответа"
                                      placeholder="">
                                    </CTextarea>
                                  </CCol>

                                  <CCol md="5">
                                    <CTextarea rows="4" v-model="newAnswersData[question.id].explanation_description" label="Полное объяснение ответа"
                                      placeholder="">
                                    </CTextarea>
                                  </CCol>
                                  
                                  <CCol md="5">
                                  <CInput v-model="newAnswersData[question.id].match" label="`Метка ответа" placeholder=""></CInput>
                                  </CCol>
                                  <!-- CCol md="2">
                                    <CSelect
                                      :value.sync="
                                        newAnswersData[question.id].person_type
                                      "
                                      label="Тип личности"
                                      :options="personTypes"
                                      placeholder=""
                                    />
                                  </CCol -->
                                  <CCol>

                                    <input type="checkbox"
                                      v-model="newAnswersData[question.id].correct" /> <label>Правильный ответ?</label>
                                  </CCol>
                                  <CCol>
                                    <CButton style="margin-top: 28px" color="success" type="button"
                                      @click="addAnswer(question.id)" :disabled="!newAnswerValid(question.id)">
                                      <span>Добавить</span>
                                    </CButton>
                                  </CCol>
                                </CRow>
                              </div>
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol>
                        <h3>Добавить вопрос</h3>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="8">
                        <CTextarea rows="4" v-model="questionData.text" label="Текст вопроса" placeholder="">
                        </CTextarea>
                      </CCol>
                      <CCol md="8">
                        <CTextarea rows="4" v-model="questionData.description" label="Описание"
                          placeholder="">
                        </CTextarea>
                      </CCol>
                      <CCol md="8">
                        <CTextarea rows="4" v-model="questionData.explanation_text" label="Короткое объяснение" placeholder="">
                        </CTextarea>
                      </CCol>
                      <CCol md="8">
                        <CTextarea rows="4" v-model="questionData.explanation_description" label="Длинное объяснение"
                          placeholder="">
                        </CTextarea>
                      </CCol>
                      <CCol>
                        <CButton style="margin-top: 28px" color="success" type="button" @click="addQuestion"
                          :disabled="!newQuestionValid()">
                          <span>Добавить</span>
                        </CButton>
                      </CCol>
                    </CRow>

                  </div>
                  <CRow>
                    <CCol>
                      <CButton color="success" type="submit" disabled>
                        <span v-if="$route.params.id">Сохранить</span>
                        <span v-else>Создать</span>
                      </CButton>
                    </CCol>
                    <CCol v-if="$route.params.id">
                      <CButton color="primary" type="button" @click="
                        router.push({
                          name: 'results',
                          params: { quizId: quiz.id },
                        })
                      ">
                        Результаты
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { debounce } from "lodash";
import { router } from "../router";
import { makeCoreUICSelectList } from "../helpers"
export default {
  name: "QuizForm",
  data() {
    return {
      quiz: null,
      questions: [],
      personTypes: [],
      answers: {
        count: -1,
      },
      newAnswersData: {},
      quizData: {
        id: null,
        text: "",
      },
      questionData: {
        text: "",
        order: null,
      },
      answersData: {
        text: "",
      },
      updloadIndex: 0,
      router: router,
    };
  },

  created() {
    this.storeSubscription = this.$store.subscribe((mutation) => {
      if (mutation.type === "quizes/getByIdSuccess") {
        this.quiz = mutation.payload;
        this.quizData.id = this.quiz.id;
        this.quizData.text = this.quiz.text;
        this.questionData.quizId = this.quiz.id;
      } else if (mutation.type === "quizes/createSuccess") {
        this.quiz = mutation.payload;
        this.quizData.id = this.quiz.id;
        this.quizData.text = this.quiz.text;
        this.questionData.quizId = this.quiz.id;
        router.push({
          name: "quiz-edit",
          params: { id: this.quiz.id },
        });
      } else if (mutation.type === "questions/createSuccess") {
        this["questions/getByQuizId"](this.quiz.id);
        this.answers[mutation.payload.id] = {};
      } else if (mutation.type === "answers/createSuccess") {
        this["answers/getByQuestionId"](mutation.payload.questionId);
      } else if (mutation.type === "questions/getByQuizIdSuccess") {
        this.questions = mutation.payload.questions;
        for (const question of this.questions) {
          this.newAnswersData = {
            ...this.newAnswersData,
            [question.id]: {
              questionId: question.id,
              text: "",
              correct: false,
              value: 0,
            },
          };
          this["answers/getByQuestionId"](question.id);
        }
      } else if (mutation.type === "answers/getByQuestionIdSuccess") {
        this.answers = {
          ...this.answers,
          [mutation.payload.questionId]: mutation.payload.answers,
        };
      } else if (mutation.type === "personTypes/getAllSuccess") {
        this.personTypes = makeCoreUICSelectList("name", "id", mutation.payload)
      }
    });
    if (this.$route.params.id) {
      this["quizes/getById"](this.$route.params.id);
      this["questions/getByQuizId"](this.$route.params.id);
    }
    this["personTypes/getAll"]();
  },
  mounted() {
    setTimeout(() => {
      const files = document.querySelectorAll("input[type='file']")
      for (const file of files) {
        file.addEventListener('click', (event) => {
          console.log(event.srcElement.dataset.index)
          this.uploadIndex = event.srcElement.dataset.index
        })
      } 
    }, 300);

  },
  beforeDestroy() {
    if (this.storeSubscription) {
      this.storeSubscription();
    }
  },
  methods: {
    newQuestionValid() {
      return this.questionData.text.trim() != "";
    },
    quizNameValid() {
      return this.quizData.text.trim() != "";
    },
    formValid() {
      return this.quizNameValid();
    },
    ...mapActions([
      "quizes/create",
      "quizes/update",
      "quizes/getById",
      "quizes/saveQuestionImage",
      "quizes/saveQuestionAudio",
      "questions/getByQuizId",
      "questions/create",
      "questions/update",
      "questions/delete",
      "answers/getByQuestionId",
      "answers/create",
      "answers/update",
      "answers/delete",
      "personTypes/getAll",
    ]),
    addQuestion() {
      this["questions/create"](this.questionData);
      this.questionData.text = "";
      this.questionData.description = "";
      this.questionData.explanation_text = "";
      this.questionData.explanation_description = "";
      this.questionData.order = 0;
    },
    deleteQuestion(index) {
      this["questions/delete"]({
        quizId: this.quiz.id,
        quizQuestionId: this.questions[index].id,
      });
      this.questions.splice(index, 1);
    },
    newAnswerValid(questionId) {
      if (this.newAnswersData[questionId]) {
        return (
          this.newAnswersData[questionId].text.trim() !== ""
        );
      }
    },
    deleteAnswer(questionId, index) {
      this["answers/delete"]({
        questionId: questionId,
        questionAnswerId: this.answers[questionId][index].id,
      });
      this.answers[questionId].splice(index, 1);
    },

    addAnswer(questionId) {
      this["answers/create"](this.newAnswersData[questionId]);
      this.newAnswersData[questionId].text = "";
      this.newAnswersData[questionId].correct = false;
    },

    requestQuizUpdate: debounce(function () {
      this.updateQuiz();
    }, 500),

    updateQuiz() {
      if (this.quizData.id) {
        this["quizes/update"]({
          id: this.quiz.id,
          text: this.quizData.text,
        });
      } else {
        this["quizes/create"]({
          text: this.quizData.text,
        });
      }
    },

    requestQuestionUpdate: debounce(function (index) {
      this.updateQuestion(index);
    }, 500),

    updateQuestion(index) {
      this["questions/update"]({
        quizId: this.quiz.id,
        id: this.questions[index].id,
        text: this.questions[index].text,
        description: this.questions[index].description,
        explanation_text: this.questions[index].explanation_text,
        explanation_description: this.questions[index].explanation_description,
        order: this.questions[index].order,
      });
    },

    uploadQuizQuestionImage(files) {
      const file = files[0];
      const reader = new FileReader();
      const saveThis = this;

      reader.addEventListener(
        "load",
        function () {
          saveThis.questions[saveThis.uploadIndex].image = reader.result;
          saveThis["quizes/saveQuestionImage"]({
            id: saveThis.questions[saveThis.uploadIndex].id,
            dataUrl: saveThis.questions[saveThis.uploadIndex].image,
          });
        },
        false
      );
      reader.readAsDataURL(file);
    },

    uploadQuizQuestionAudio(files) {
      const file = files[0];
      const reader = new FileReader();
      const saveThis = this;

      reader.addEventListener(
        "load",
        function () {
          saveThis.questions[saveThis.uploadIndex].audio = reader.result;
          saveThis["quizes/saveQuestionAudio"]({
            id: saveThis.questions[saveThis.uploadIndex].id,
            dataUrl: saveThis.questions[saveThis.uploadIndex].audio,
          });
        },
        false
      );
      reader.readAsDataURL(file);
    },

    // requestQuestionUpdate: debounce(function (index) {
    //   this.updateQuestion(index);
    // }, 500),

    // updateQuestion(index) {
    //   this["questions/update"]({
    //     quizId: this.quiz.id,
    //     id: this.questions[index].id,
    //     text: this.questions[index].text,
    //   });
    // },

    requestAnswerUpdate: debounce(function (questionId, index) {
      this.updateAnswer(questionId, index);
    }, 500),

    updateAnswer(questionId, index) {
      this["answers/update"]({
        questionId,
        id: this.answers[questionId][index].id,
        text: this.answers[questionId][index].text,
        explanation_text: this.answers[questionId][index].explanation_text,
        explanation_description: this.answers[questionId][index].explanation_description,
        match: this.answers[questionId][index].match,
        correct: this.answers[questionId][index].correct,
        person_type: this.answers[questionId][index].person_type,
      });
    },
  },
};
</script>
